/*
 * @Author: 曾少敏
 * @Date: 2021-08-13 15:39:27
 * @LastEditors: 曾少敏
 * @LastEditTime: 2021-08-13 15:42:43
 * @Description:
 */
import { createApp } from "vue";
import Home from "./Home.vue";
import "@/styles/global.less";
createApp(Home).mount("#app");
